import {AwsS3MultipartOptions} from "@uppy/aws-s3-multipart";
import {getApiClient} from "../../../apiClient";

export default function listParts(authToken: string): AwsS3MultipartOptions['listParts'] {
  const apiClient = getApiClient(authToken)
  return async (file, { uploadId, key}) => {
    const response = await apiClient.listParts(key, uploadId)
    return response.parts
  }
}
