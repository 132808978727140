import {AwsS3MultipartOptions} from "@uppy/aws-s3-multipart";
import {getApiClient} from "../../../apiClient";

export default function completeMultipartUpload(authToken: string): AwsS3MultipartOptions['completeMultipartUpload'] {
  const apiClient = getApiClient(authToken);
  return async (file, { uploadId, key, parts}) => {
    await apiClient.completeMultipartUpload(key, uploadId, parts)
    return {}
  }
}
