import { AwsS3MultipartOptions } from "@uppy/aws-s3-multipart";
import abortMultipartUpload from "./abortMultipartUpload";
import createMultipartUpload from "./createMultipartUpload";
import completeMultipartUpload from "./completeMultipartUpload";
import listParts from "./listParts";
import signPart from "./signPart";
import Uppy from "@uppy/core";
import {useEffect} from "react";

type MultipartUploadFunctions = Pick<
  AwsS3MultipartOptions,
  'abortMultipartUpload' | 'createMultipartUpload' | 'completeMultipartUpload' | 'listParts' | 'signPart'
>

function getMultipartUploadFunctions (authToken: string): MultipartUploadFunctions {
  return {
    abortMultipartUpload: abortMultipartUpload(authToken),
    createMultipartUpload: createMultipartUpload(authToken),
    completeMultipartUpload: completeMultipartUpload(authToken),
    listParts: listParts(authToken),
    signPart: signPart(authToken),
  }
}

/*
  Provides functions that can perform multipart uploaded processes with access to authToken for authentication
  against the API. As first pass this is injected into the function, but once authentication is set-up in the app,
  this could be grabbed within this effect.
 */
export function useAuthenticatedMultipartUploadFunctions(uppy: Uppy, authToken: string) {
  useEffect(
    () => {
      const AwsS3MultipartPlugin = uppy.getPlugin('AwsS3Multipart')
      if (AwsS3MultipartPlugin) {
        AwsS3MultipartPlugin.setOptions(getMultipartUploadFunctions(authToken))
      } else {
        throw new Error(
          'Unable to get AwsS3Multipart plugin from uppy. Ensure it has been "used" on uppy instance.'
        )
      }
    },
  [uppy, authToken]
  )
}
