import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import UploadPage from "./Components/UploadPage";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { getAppConfig } from "./config";
import { LoginCallback, Security } from "@okta/okta-react";
import { RequireAuthForSubRoutes } from "./Components/RequireAuthForSubRoutes";
import "./style/app.scss";

const oktaAuth = new OktaAuth(getAppConfig().oidc);

function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div className="App">
        <div className="AppContainer">
          <a href="https://nanoporetech.com">
            <img
              src="/assets/ont-logo.svg"
              alt="Oxford Nanopore Technologies"
              width="280"
            />
          </a>
          <h1>Device Telemetry Uploader</h1>
          <Routes>
            <Route
              path="/login/callback"
              element={
                <LoginCallback loadingElement={<span>Loading...</span>} />
              }
            />
            <Route path="/" element={<RequireAuthForSubRoutes />}>
              <Route path="" element={<UploadPage />} />
            </Route>
          </Routes>
        </div>
      </div>
    </Security>
  );
}

export default App;
