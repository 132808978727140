import Uploader from "./S3MultipartUploader/Uploader";
import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import "../style/uploader.scss";

export default function UploadPage() {
  const { authState } = useOktaAuth();

  if (!authState?.isAuthenticated) {
    return (
      <p>
        App configured incorrectly. This page should be behind a route that
        enforces authentication.
      </p>
    );
  }

  return (
    <>
      <p>
        Upload Oxford Nanopore Device telemetry by adding the zipped telemetry
        output from your device's MinKNOW software to the upload area below.
      </p>
      <p className="warn">
        <strong>Caution:</strong> Do not close this browser tab until your
        uploads are complete.
      </p>

      <Uploader authToken={authState?.accessToken?.accessToken || ""} />
      <div className="help">
        <img
          src="/assets/circle-question.svg"
          width="32"
          height="32"
          alt="Help icon"
        />
        <span>
          <strong>Need help?</strong>{" "}
          <a
            href="https://nanoporetech.com/support"
            target="_blank"
            rel="noreferrer"
          >
            View our Support page
          </a>
          .
        </span>
      </div>
    </>
  );
}
