import {useOktaAuth} from "@okta/okta-react";
import {useState} from "react";
import {toRelativeUrl} from "@okta/okta-auth-js";
import {Outlet} from "react-router-dom";

// This is adapted from the Okta example at https://github.com/okta/okta-react/blob/9e76990f82f86449d402481d77363c964ae1d01a/samples/routing/react-router-dom-v6/src/components/SecureRoute.tsx
// Changed to not use useEffect as it shouldn't be necessary and is inefficient.
export function RequireAuthForSubRoutes() {
  const { oktaAuth, authState } = useOktaAuth()
  const [previousOktaAuth, setPreviousOktaAuth] = useState(oktaAuth)

  if ((authState && !authState.isAuthenticated) || oktaAuth !== previousOktaAuth) {
    const originalUri = toRelativeUrl(window.location.href, window.location.origin)
    oktaAuth.setOriginalUri(originalUri)
    oktaAuth.signInWithRedirect()
  }

  if (oktaAuth !== previousOktaAuth) {
    setPreviousOktaAuth(oktaAuth)
  }

  if(!authState || !authState?.isAuthenticated) {
    return <span>"Loading..."</span>
  }

  return (<Outlet />)
}
