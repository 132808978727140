import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import AwsS3Multipart from "@uppy/aws-s3-multipart";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";

import { useAuthenticatedMultipartUploadFunctions } from "./multipartUploadFunctions";

// 1 megabyte
const MB = 1024 * 1024;

const uppy = new Uppy({restrictions: { allowedFileTypes: [".zip"] } })
  // Add s3 multipart uploader and set minimum chunk size to 25MB
  .use(AwsS3Multipart, { getChunkSize: () => 25 * MB });

type UploaderProps = {
  authToken: string;
};

function Uploader({ authToken }: UploaderProps) {
  useAuthenticatedMultipartUploadFunctions(uppy, authToken);
  return <Dashboard uppy={uppy} showProgressDetails={true} width="100%" />;
}

export default Uploader;
