import {AwsS3MultipartOptions} from "@uppy/aws-s3-multipart";
import {getApiClient} from "../../../apiClient";

export default function signPart(authToken: string): AwsS3MultipartOptions['signPart'] {
  const apiClient = getApiClient(authToken)
  return async (file, { uploadId, key, partNumber}) => {
    const result = await apiClient.getSignedPartUploadUrl(key, uploadId, partNumber)
    return {
      url: result.url,
      headers: {
        // Signed URl does not allow Content-Type header to be sent as this
        // causes a 403, signature does not match error.
        'Content-Type': ''
      }
    }
  }
}
