import {getAppConfig} from "./config";

type CreateMultipartUploadResponse = {
  key: string,
  uploadId: string
}
const isCreateMultipartUploadResponse = (data: any): data is CreateMultipartUploadResponse => (
  data.key && data.uploadId
)

type GetSignedUploadPartUrlResponse = {
  url: string,
}
const isGetSignedUploadPartUrlResponse = (data: any): data is GetSignedUploadPartUrlResponse => (
  data.url
)

type UploadPart = {
  ETag: string,
  PartNumber: number,
  Size: number,
}
type ListPartsResponse = {
  parts: UploadPart[],
}
const isListPartsResponse = (data: any): data is ListPartsResponse => {
  const { parts } = data
  if (parts === undefined) {
    return false
  }
  return (
    Array.isArray(parts) && parts.every((part: any) => part.ETag && part.PartNumber && part.Size)
  )
}

export class ApiClient {
  apiBaseUrl: URL
  authToken: string
  leadingSlashRegex: RegExp = new RegExp('^/')


  // TODO: handle authToken being refreshed
  constructor(authToken: string, apiBaseUrl: string | URL) {
    this.authToken = authToken
    // ensure there is a trailing slash on apiBaseUrl as this ensures URL will add any subsequent paths
    // to the path that already exists on the URL.
    this.apiBaseUrl = this.ensureTrailingSlashOnURL(new URL(apiBaseUrl))
  }

  private ensureTrailingSlashOnURL(url: URL): URL {
    const updatedUrl = new URL(url)
    if (!updatedUrl.pathname.endsWith('/')) {
      updatedUrl.pathname += '/'
    }
    return updatedUrl
  }

  private getAuthHeaders(): { Authorization: string } {
    return {
      Authorization: `Bearer ${this.authToken}`
    }
  }

  /*
  Create a URL using the data provided, correctly setting the base URL as
  configured in this class.
   */
  private createUrl(path: string, params?: Record<string, string>): URL {
    // need to replace leading slash to ensure path added to any pre-existing path on the base URL
    const url = new URL(this.replaceLeadingSlash(path), this.apiBaseUrl)
    if (params) {
      Object.entries(params).forEach(([name, value]) => {
        url.searchParams.set(name, value)
      })
    }
    return url
  }

  private replaceLeadingSlash(value: string): string {
    return value.replace(this.leadingSlashRegex, '')
  }

  private getRequest(path: string, params?: Record<string, string>): Promise<Response> {
    return fetch(
      this.createUrl(path, params),
      {
        method: 'get',
        headers: this.getAuthHeaders()
      }
    )
  }

  private jsonRequest(method: 'delete' | 'post' | 'put', path: string, data: any): Promise<Response> {
    return fetch(
      this.createUrl(path),
      {
        method,
        body: JSON.stringify(data),
        headers: {
          ...this.getAuthHeaders(),
          'Content-Type': 'application/json',
        }
      }
    )
  }

  private async jsonPost(path: string, data: any): Promise<Response> {
    return this.jsonRequest('post', path, data)
  }

  private async jsonDelete(path: string, data: any): Promise<Response> {
    return this.jsonRequest('delete', path, data)
  }

  /*
  Initiate a multipart upload to S3. This needs to be done before anything can be
  uploaded using multipart upload.
   */
  async createMultipartUpload(key: string): Promise<CreateMultipartUploadResponse> {
    const response = await this.jsonPost(
      '/upload/multipart/create',
      { key },
    )
    if (response.status >= 400) {
      throw new Error(`Got error response when creating multipart upload: ${response.status}.`)
    }
    const data = await response.json()
    const result = {
      key: data.key,
      uploadId: data.upload_id,
    }
    if (!isCreateMultipartUploadResponse(result)) {
      throw new Error('Response from createMultipartUpload was not as expected.')
    }
    return result
  }

  async abortMultipartUpload(key: string, uploadId: string): Promise<void> {
    const response = await this.jsonDelete('/upload/multipart', { key, upload_id: uploadId })
    if (response.status >= 400) {
      throw new Error(`Got error response when aborting multipart upload: ${response.status}.`)
    }
  }

  async getSignedPartUploadUrl(key: string, uploadId: string, partNumber: number): Promise<GetSignedUploadPartUrlResponse> {
    const response = await this.getRequest(
      '/upload/multipart/upload_part_url',
      {
        key,
        upload_id: uploadId,
        part_number: partNumber.toString(),
      }
    )
    if (response.status >= 400) {
      throw new Error(`Got error response when getting signed upload part url: ${response.status}.`)
    }

    const result = await response.json()
    if (!isGetSignedUploadPartUrlResponse(result)) {
      throw new Error('Response from getSignedPartUrl was not as expected.')
    }
    return result
  }

  async completeMultipartUpload(
    key: string, uploadId: string, parts: Array<{ ETag?: string, PartNumber?: number }>
  ) {
    const response = await this.jsonPost('/upload/multipart/complete', { key, upload_id: uploadId, parts })
    if (response.status >= 400) {
      throw new Error(`Got error response when completing multipart upload: ${response.status}.`)
    }
  }

  async listParts(key: string, uploadId: string): Promise<ListPartsResponse> {
    const response = await this.getRequest(
      '/upload/multipart/list_parts',
      {
        key,
        upload_id: uploadId,
      }
    )
    if (response.status >= 400) {
      throw new Error(`Got error response when listing parts: ${response.status}.`)
    }

    const result = await response.json()
    if (!isListPartsResponse(result)) {
      throw new Error('Response from listParts was not as expected.')
    }
    return result
  }
}

export function getApiClient(authToken: string) {
  const { apiBaseUrl } = getAppConfig()
  return new ApiClient(authToken, apiBaseUrl)
}
