type OidcConfig = {
  clientId: string
  issuer: string
  redirectUri: string
  pkce: boolean
  scopes: string[]
}

type AppConfig = {
  apiBaseUrl: string
  oidc: OidcConfig
}

function raiseForUnsetEnvVarValue(varName: string, varValue: string | undefined): string {
  if (varValue === undefined) {
    throw new Error(`Environment variable '${varName}' must have a value set.`)
  }
  return varValue
}

function getApiBaseUrl(): string {
  // Can't get this using process.env[REACT_APP_API_BASE_URL] using a string defined as a constant for reuse
  // as this isn't replace properly at build time. Therefore, it has been referenced as process.env.VAR_NAME.
  return raiseForUnsetEnvVarValue(
    'REACT_APP_API_BASE_URL',
    process.env.REACT_APP_API_BASE_URL,
  )
}

function getOidcConfig(): OidcConfig {
  const clientId = raiseForUnsetEnvVarValue(
    'REACT_APP_OIDC_CLIENT_ID',
    process.env.REACT_APP_OIDC_CLIENT_ID
  )
  const issuer = raiseForUnsetEnvVarValue(
    'REACT_APP_OIDC_ISSUER',
    process.env.REACT_APP_OIDC_ISSUER
  )
  return {
    clientId,
    issuer,
    redirectUri: `${window.location.origin}/login/callback`,
    pkce: true,
    scopes: ['openid', 'profile', 'email'],
  }
}

export function getAppConfig(): AppConfig {
  return {
    apiBaseUrl: getApiBaseUrl(),
    oidc: getOidcConfig(),
  }
}
